import React from 'react';
import {
  Container,
  Segment,
} from 'semantic-ui-react';

import './Footer.scss';

class Footer extends React.Component {
  render() {
    return (
      <Segment className="Footer" inverted vertical>
        <Container>
          <p className="Footer-quote">
            “Don't let your happiness depend on something you may lose.”
          </p>
          <p className="Footer-author">
            C.S. Lewis
          </p>
        </Container>
      </Segment>
    );
  }
}

export default Footer;
