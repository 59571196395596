import React from 'react';
import {
  Menu,
} from 'semantic-ui-react';

import history from '../../history';

const routes = [
  { pathname: '/', title: 'Home' },
  { pathname: '/portfolio/', title: 'Portfolio' }
];

const NavigationMenu = () =>
  <React.Fragment>
    {routes.map(route => (
      <Menu.Item
        key={route.pathname}
        as='a'
        onClick={() => history.push(route.pathname)}
        active={history.location.pathname === route.pathname}>
        {route.title}
      </Menu.Item>
    ))}
  </React.Fragment>

export default NavigationMenu;
