import React from 'react';
import {
  Container,
  Segment,
} from 'semantic-ui-react';

import ResponsiveContainer from '../../components/ResponsiveContainer/ResponsiveContainer';
import Footer from '../../components/Footer';

import Portfolio from './Portfolio/Portfolio';
import PortfolioItem from './PortfolioItem/PortfolioItem';

const PortfolioPage = () => (
  <ResponsiveContainer>
    <Segment vertical>
      <Container>
        <Portfolio title="Portfolio">
          <PortfolioItem
            title="Strickleserl"
            start="2018-04"
            end="2018-05"
            url="https://www.strickleserl.com"
            image="/portfolio/strickleserl.png"
            bullets={[
              'Built using WordPress',
              'Customized theme',
            ]}
            skills={['PHP', 'WordPress', 'MySQL']}
          />
          <PortfolioItem
            title="Power Machinery Co."
            start="2015-08"
            end="2015-09"
            url="https://www.pmc-saudi.com"
            image="/portfolio/pmc-saudi.png"
            bullets={[
              'Built using ProcessWire, a very versatile CMS',
              'Integrated online PDF reader',
            ]}
            skills={['PHP', 'ProcessWire', 'MySQL']}
          />
          <PortfolioItem
            title="Ulrich Müller Maschinenhandel"
            start="2014-08"
            end="2014-09"
            url="http://www.ummh.de"
            image="/portfolio/ummh.png"
            bullets={[
              'Built using WordPress with custom post types and custom taxonomies',
              'Available in English and German',
              'Complementary Java application that can create PDF exports',
            ]}
            skills={['PHP', 'WordPress', 'Java', 'MySQL']}
          />
          <PortfolioItem
            title="Mobi-ePub"
            start="2013-08"
            end="2013-09"
            url="http://www.mobiepub.com"
            image="/portfolio/mobi-epub.png"
            bullets={[
              'Built using WordPress',
              'Customised theme',
              'Interactive, real-time price calculator',
            ]}
            skills={['PHP', 'WordPress', 'MySQL']}
          />
        </Portfolio>
      </Container>
    </Segment>
    <Footer />
  </ResponsiveContainer>
)
export default PortfolioPage;
