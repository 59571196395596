import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Button,
  Container,
  Menu,
  Responsive,
  Segment,
  Visibility,
} from 'semantic-ui-react';

import sendEmail from '../../domain/sendEmail';

import Heading from '../Heading';
import NavigationMenu from './NavigationMenu';

import './DesktopContainer.scss';

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends React.Component {
  state = {}

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  render() {
    const { large, children } = this.props
    const { fixed } = this.state

    return (
      <Responsive
        className="DesktopContainer"
        minWidth={Responsive.onlyTablet.minWidth}
      >
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            className={classnames("DesktopContainer-segment", {
              'DesktopContainer-segment--large': large
            })}
            inverted
            textAlign='center'
            vertical
          >
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size='large'
            >
              <Container>
                <NavigationMenu />
                <Menu.Item position='right'>
                  <Button as='a' inverted={!fixed} onClick={sendEmail}>
                    Contact
                  </Button>
                </Menu.Item>
              </Container>
            </Menu>
            {large && <Heading />}
          </Segment>
        </Visibility>
        {children}
      </Responsive>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

export default DesktopContainer;
