import React from 'react';
import {
  Item,
  Card,
  Image,
  Placeholder,
  List,
  Icon,
  Label,
} from 'semantic-ui-react';

import getDateSpan from '../../../domain/getDateSpan';
import getGroupedSkills from '../../../domain/getGroupedSkills';

import './PortfolioItem.scss';

const PortfolioItem = ({ title, start, end, url, image, bullets, skills }) => {
  const { startDate, endDate } = getDateSpan(start, end);
  const groupedSkills = getGroupedSkills(skills);

  return (
    <Item className="PortfolioItem">
      <a className="PortfolioItem-cardLink" href={url} target="_blank" rel="noopener noreferrer">
        <Card className="PortfolioItem-card">
          <Image className="PortfolioItem-image" src={image} rounded />
          <Placeholder className="PortfolioItem-placeholder" />
        </Card>
      </a>
      <Item.Content>
        <Item.Header as="a" href={url} target="_blank" rel="noopener noreferrer">{title}</Item.Header>
        <Item.Meta>
          <span>{startDate} - {endDate}</span>
        </Item.Meta>
        <Item.Description>
          <List className="TimeLineItem-bullets">
            {bullets.map((bullet) => (
              <List.Item key={bullet}>
                <Icon name="angle right" />
                <List.Content>{bullet}</List.Content>
              </List.Item>
            ))}
          </List>
        </Item.Description>
        <Item.Extra>
          {Object.keys(groupedSkills).map((groupName) => (
            <Label key={groupName} className="TimeLineItem-skill" content={groupedSkills[groupName].join(', ')} icon={groupName} />
          ))}
        </Item.Extra>
      </Item.Content>
    </Item>
  );
};

export default PortfolioItem;
