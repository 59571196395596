import React from 'react';
import {
  Header,
  Item,
} from 'semantic-ui-react';

import './Portfolio.scss';

const Portfolio = ({ title, children }) => (
  <div className="Portfolio">
    <Header className="Portfolio-header" as='h3'>
      {title}
    </Header>
    <Item.Group divided>
      {children}
    </Item.Group>
  </div>
);

export default Portfolio;
