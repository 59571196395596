import React from 'react';
import {
  Container,
  Segment,
} from 'semantic-ui-react';

import ResponsiveContainer from '../../components/ResponsiveContainer/ResponsiveContainer';
import Footer from '../../components/Footer';

import TimeLine from './Timeline/TimeLine';
import TimeLineItem from './TimeLineItem/TimeLineItem';

const HomePage = () => (
  <ResponsiveContainer large>
    <Segment vertical>
      <Container>
        <TimeLine title="Work Experience">
          <TimeLineItem
            startDate="2018-11"
            title="Software Developer | Node.js | React"
            location="CHECK24 Versicherungsservice GmbH"
            bullets={[
              'Web app where customers can analyze their insurance contracts',
              'Working as a full-stack developer',
              'DevOps environment',
            ]}
            skills={['Node.js', 'React', 'MongoDB', 'Docker', 'Atlassian Tools', 'Git']}
          />
          <TimeLineItem
            startDate="2017-09"
            endDate="2018-10"
            title="Software Developer | Node.js | React"
            location="Self-employed"
            bullets={[
              'Developed a social media website from the ground up',
              'Personal profile, friends, real-time chat, search, geolocation',
            ]}
            skills={['Node.js', 'React', 'MongoDB', 'Firebase', 'Digital Ocean', 'Git']}
          />
          <TimeLineItem
            startDate="2017-06"
            endDate="2017-08"
            title="Software Developer | C# | React"
            location="Softwire/Ghyston"
            bullets={[
              'Summer internship at local software development company',
              'Developed tablet-based barcode scanner',
            ]}
            skills={['C#', 'React', 'TypeScript', 'MySQL', 'Atlassian Tools', 'Git' ]}
          />
          <TimeLineItem
            startDate="2014-09"
            endDate="2017-06"
            title="Web Developer | PHP"
            location="Self-employed"
            bullets={[
              'Took on web development projects for four different clients',
            ]}
            skills={['PHP', 'WordPress', 'ProcessWire']}
          />
        </TimeLine>
        <TimeLine title="Education">
          <TimeLineItem
            startDate="2014-09"
            endDate="2018-06"
            title="Computer Systems Engineering (MEng)"
            location="University of Bath"
            bullets={[
              'Attained first-class honors',
              'Equivalent to 3.7 - 4.0 GPA'
            ]}
          />
          <TimeLineItem
            startDate="2012-09"
            endDate="2014-06"
            title="Bilingual IB Diploma"
            location="British International School - Jeddah"
            bullets={[
              '40 points in total',
              '7\'s in Math, Physics and Computer Science (all at Higher Level)',
              '6 – 6 – 5 in other subjects',
              'Equivalent to 1.3 in Abitur',
            ]}
          />
        </TimeLine>
      </Container>
    </Segment>
    <Footer />
  </ResponsiveContainer>
)
export default HomePage;
