import React from 'react';
import {
  List,
  Icon,
  Label,
} from 'semantic-ui-react';

import getDateSpan from '../../../domain/getDateSpan';
import getGroupedSkills from '../../../domain/getGroupedSkills';

import './TimeLineItem.scss';

const TimeLineDate = ({ start, end }) => {
  if (!start) {
    return null;
  }

  const { startDate, endDate, difference } = getDateSpan(start, end);
  
  return (
    <p className="TimeLineDate">
      <span>
        {startDate}
      </span>
      <span> – </span>
      <span>
        {endDate}
      </span>
      <span className="TimeLineDate-months"> ({difference})</span>
    </p>
  );
};

const TimeLineItem = ({ startDate, endDate, title, location, bullets, skills }) => {
  const groupedSkills = getGroupedSkills(skills);

  return (
    <div className="TimeLineItem">
      <Icon className="TimeLineItem-anchor" name="circle" size="small" />
      <TimeLineDate start={startDate} end={endDate} />
      <p className="TimeLineItem-title">
        <span className="TimeLineItem-primaryTitle">{title}</span>
        <Icon className="TimeLineItem-separator" name="circle" />
        <span className="TimeLineItem-secondaryTitle">{location}</span>
      </p>
      <List className="TimeLineItem-bullets">
        {bullets.map((bullet) => (
          <List.Item key={bullet}>
            <Icon name="angle right" />
            <List.Content>{bullet}</List.Content>
          </List.Item>
        ))}
      </List>
      {Object.keys(groupedSkills).map((groupName) => (
        <Label key={groupName} className="TimeLineItem-skill" content={groupedSkills[groupName].join(', ')} icon={groupName} />
      ))}
    </div>
  )
};

export default TimeLineItem;
