import React from 'react';
import { Header } from 'semantic-ui-react';

import './TimeLine.scss';

const TimeLine = ({ title, children }) => (
  <div className="TimeLine">
    <Header className="TimeLine-header" as='h3'>
      {title}
    </Header>
    <div className="TimeLine-items">{children}</div>
  </div>
);

export default TimeLine;
