const skillGroups = {
  code: new Set(['C#', 'Node.js', 'React', 'TypeScript', 'PHP', 'WordPress', 'ProcessWire', 'Java']),
  database: new Set(['MongoDB', 'Firebase', 'MySQL']),
  docker: new Set(['Docker']),
  'digital ocean': new Set(['Digital Ocean']),
  bitbucket: new Set(['Atlassian Tools']),
  'code branch': new Set(['Git']),
};

const getGroupedSkills = (skills) => {
  if (!skills) {
    return {};
  }
  return Object.keys(skillGroups).reduce((acc, groupName) => {
    const skillType = skillGroups[groupName];
    const groupedSkills = skills.filter((skill) => skillType.has(skill));
    if (groupedSkills.length) {
      acc[groupName] = groupedSkills;
    }
    return acc;
  }, {});
};

export default getGroupedSkills;
