import React from 'react';
import PropTypes from 'prop-types';

import DesktopContainer from './DesktopContainer';
import MobileContainer from './MobileContainer';

const ResponsiveContainer = ({ large, children }) => (
  <div>
    <DesktopContainer large={large}>{children}</DesktopContainer>
    <MobileContainer large={large}>{children}</MobileContainer>
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

export default ResponsiveContainer;
