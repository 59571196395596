const getLocaleString = (date) => {
  const options = { year: 'numeric', month: 'short' };
  return date.toLocaleString('en-US', options).toUpperCase();
}

const formatYears = (years) => {
  if (years === 0) {
    return '';
  }
  if (years === 1) {
    return '1 YEAR';
  }
  return `${years} YEARS`;
};

const formatMonths = (years, months) => {
  if (years !== 0 && months === 0) {
    return '';
  }
  if (months === 1) {
    return '1 MONTH';
  }
  return `${months} MONTHS`;
};

const formatDate = (years, months) => {
  const formattedYears = formatYears(years);
  const formattedMonths = formatMonths(years, months);
  if (!formattedYears) {
    return formattedMonths;
  }
  if (!formattedMonths) {
    return formattedYears;
  }
  return `${formattedYears} ${formattedMonths}`;
};

const getDifference = (start, end) => {
  let months;
  months = (end.getFullYear() - start.getFullYear()) * 12;
  months -= start.getMonth();
  months += end.getMonth() + 1;
  const years = Math.floor(months / 12);
  const extraMonths = months - years * 12;
  return formatDate(years, extraMonths);
};

const getDateSpan = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = endDate ? new Date(endDate) : new Date();
  const difference = getDifference(start, end);
  return {
    startDate: getLocaleString(start),
    endDate: endDate ? getLocaleString(end) : 'NOW',
    difference,
  };
};

export default getDateSpan;
