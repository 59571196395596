import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Header,
  Icon,
} from 'semantic-ui-react';

import './Heading.scss';

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
const Heading = ({ mobile }) => (
  <Container className="Heading" text>
    <Header
      className="Heading-title"
      as='h1'
      content='Jonas Müller'
      inverted
    />
    <Header
      className="Heading-subtitle"
      as='h2'
      content='Software Developer | Node.js | React'
      inverted
    />
    <div className="Heading-links">
      <a href="https://www.linkedin.com/in/jonas-mueller-2489a4168/" target="_blank" rel="noopener noreferrer">
        <Icon name="linkedin" size="huge" />
      </a>
      <a href="https://github.com/sanjom/" target="_blank" rel="noopener noreferrer">
        <Icon name="github" size="huge" />
      </a>
      <a href="https://www.upwork.com/freelancers/~01dc06654a3019a30e/" target="_blank" rel="noopener noreferrer">
        <Icon name="id card outline" size="huge" />
      </a>
    </div>
  </Container>
)

Heading.propTypes = {
  mobile: PropTypes.bool,
}

export default Heading;
